import { render, staticRenderFns } from "./DayProjectBlock.vue?vue&type=template&id=936c6cc0&scoped=true"
import script from "./DayProjectBlock.vue?vue&type=script&lang=js"
export * from "./DayProjectBlock.vue?vue&type=script&lang=js"
import style0 from "./DayProjectBlock.vue?vue&type=style&index=0&id=936c6cc0&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936c6cc0",
  null
  
)

export default component.exports